<template>
  <div :class="`select-none duration-300 ease w-full ${theme === 'light' && !special ? 'text-black' : 'text-white'}`">
    <BaseLabel
      :hide-asterisk="hideAsterisk"
      :label="label"
      :required="required"
      :special
      :theme />
    <input
      v-model="modelValue"
      v-bind="$attrs"
      class="h-10 w-full border rounded-lg px-2 py-1 outline-none backdrop-blur-12 backdrop-grayscale-50 duration-200 ease focus:border-none focus:bg-highlight-2/20 focus:ring-1.6px focus:ring-highlight required:border-solid"
      :class="error
        ? `${theme === 'light' && !special ? 'border-rose-6' : 'border-rose'} bg-rose/15`
        : `${theme === 'light' ? 'border-white-5' : 'border-black-5'} bg-gray/15`">
    <BaseErrorMessage
      v-if="!hideError"
      :message="error"
      :special
      :theme />
  </div>
</template>

<script setup lang="ts">
type Props = {
  error?: string;
  hideAsterisk?: boolean;
  hideError?: boolean;
  icon?: string;
  label?: string;
  required?: boolean;
  special?: boolean;
  theme?: 'light' | 'dark';
};
defineProps<Props>();

const modelValue = defineModel<number | string>();
</script>
