import { computed, reactive, watch } from 'vue';

const LOCAL_STORAGE_KEY = 'theme_settings';

type ThemeOption = 'light' | 'dark' | 'auto';
type ThemeResult = 'light' | 'dark';

interface IThemeSettings {
  alerts: ThemeOption;
  hub: ThemeOption;
  issues: ThemeOption;
}
interface ICurrentThemes {
  alerts: ThemeResult;
  hub: ThemeResult;
  issues: ThemeResult;
}

function getStoredSettings(): IThemeSettings {
  const storedSettings = localStorage.getItem(LOCAL_STORAGE_KEY);
  return storedSettings
    ? JSON.parse(storedSettings)
    : { alerts: 'auto', hub: 'auto', issues: 'auto' };
}

function getTheme(setting: ThemeOption): ThemeResult {
  if (setting === 'auto') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }
  return setting;
}

const globalThemeState = reactive<{
  settings: IThemeSettings;
  currentTheme: ICurrentThemes;
}>({
  currentTheme: {
    alerts: getTheme(getStoredSettings().alerts),
    hub: getTheme(getStoredSettings().hub),
    issues: getTheme(getStoredSettings().issues),
  },
  settings: getStoredSettings(),
});

watch(() => globalThemeState.settings, (newSettings) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newSettings));
  updateTheme();
}, { deep: true });

function updateTheme() {
  globalThemeState.currentTheme = {
    alerts: getTheme(globalThemeState.settings.alerts),
    hub: getTheme(globalThemeState.settings.hub),
    issues: getTheme(globalThemeState.settings.issues),
  };

  const isDarkAlerts = globalThemeState.currentTheme.alerts === 'dark';
  const isDarkHub = globalThemeState.currentTheme.hub === 'dark';
  const isDarkIssues = globalThemeState.currentTheme.issues === 'dark';

  document.documentElement.classList.toggle('dark', isDarkHub);
  document.documentElement.setAttribute('data-theme-alerts', isDarkAlerts ? 'dark' : 'light');
  document.documentElement.setAttribute('data-theme-hub', isDarkHub ? 'dark' : 'light');
  document.documentElement.setAttribute('data-theme-issues', isDarkIssues ? 'dark' : 'light');
}

export function useTheme() {
  updateTheme();

  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  mediaQuery.addEventListener('change', updateTheme);

  return {
    currentTheme: computed(() => globalThemeState.currentTheme),
    themeSettings: computed({
      get: () => globalThemeState.settings,
      set: (value) => {
        Object.assign(globalThemeState.settings, value);
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(globalThemeState.settings));
      },
    }),
  };
}
