<template>
  <label
    class="flex cursor-pointer select-none gap-2 duration-300 ease"
    :class="theme === 'light' && !special ? 'text-black' : 'text-white'">
    <span
      class="my-auto flex border rounded-md duration-200 ease hw-5"
      :class="theme === 'light' ? 'border-white-5 bg-black/5' : 'border-black-5 bg-white/10'">
      <i-fas-check class="m-auto duration-200 ease" :class="modelValue ? 'size-80%' : 'size-0'" />
    </span>
    <input
      v-model="modelValue"
      :false-value="false"
      hidden
      :true-value="true"
      type="checkbox">
    {{ label }}</label>
</template>

<script setup lang="ts">
type Props = {
  label: string;
  special?: boolean;
  theme?: 'light' | 'dark';
};
defineProps<Props>();

const modelValue = defineModel({ default: false, required: true });
</script>
