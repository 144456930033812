<template>
  <GuestLayout>
    <div
      class="m-auto flex flex-col overflow-hidden rounded-3xl p-10 backdrop-blur-md duration-300 ease"
      :class="currentTheme.hub === 'light' ? 'glass-light' : 'glass-dark'">
      <div class="grid grid-cols-2 gap-5">
        <div class="flex">
          <img
            class="m-auto w-52 select-none"
            draggable="false"
            src="../../assets/logo_blank.png">
        </div>
        <form
          class="w-80 flex flex-col"
          @submit.prevent="login">
          <BaseInput
            v-model="form.email"
            autocomplete="email"
            :error="form.errors.email"
            hide-asterisk
            label="E-Mail"
            name="email"
            special
            :theme="currentTheme.issues"
            type="email" />
          <BaseInput
            v-model="form.password"
            autocomplete="current-password"
            :error="form.errors.password"
            hide-asterisk
            label="Passwort"
            name="password"
            special
            :theme="currentTheme.issues"
            type="password" />
          <BaseCheckbox
            v-model="form.remember"
            label="Eingeloggt bleiben"
            special
            :theme="currentTheme.issues" />
          <BaseButton
            class="mt-5"
            label="Login"
            special
            :theme="currentTheme.issues"
            type="submit" />
        </form>
      </div>
    </div>
  </GuestLayout>
</template>

<script setup lang="ts">
import { useTheme } from '@/helpers/theme';
import { useForm } from '@inertiajs/vue3';

const { currentTheme } = useTheme();

const form = useForm({
  email: undefined,
  password: undefined,
  remember: false,
});

function login() {
  form.post(route('login.store'), {
    onError: () => form.reset('password'),
  });
}
</script>

<style scoped>
.glass-light {
  border: solid 8px #FFFFFF3A;
  box-shadow: inset 0 0 80px 40px #FFF3;
}
.glass-dark {
  border: solid 8px #0006;
  box-shadow: inset 0 0 120px 50px #0005;
}
</style>
