import { useTheme } from '@/helpers/theme';
import { createInertiaApp } from '@inertiajs/vue3';
import { createHead } from '@unhead/vue';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import localeData from 'dayjs/plugin/localeData';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createApp, type DefineComponent, h } from 'vue';
import { ZiggyVue } from 'ziggy-js';
import '@unocss/reset/tailwind.css';
import '../css/main.css';
import 'uno.css';

const head = createHead();

createInertiaApp({
  resolve: name => resolvePageComponent(`./pages/${name}.vue`, import.meta.glob<DefineComponent>('./pages/**/*.vue')),
  setup({ App, el, plugin, props }) {
    return createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
      .use(head)
      .use(useTheme)
      .mount(el);
  },
});

dayjs.locale(de);
dayjs.extend(localeData);
