<template>
  <div
    class="h-full w-full flex duration-300 ease"
    :class="currentTheme.hub === 'light' ? 'bg-white' : 'bg-black'">
    <div
      class="absolute h-screen w-screen duration-300 ease"
      :class="currentTheme.hub === 'light' ? 'opacity-80' : 'opacity-90'"
      :style="{
        'backgroundImage': `url(${backgroundPath})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }" />
    <main class="h-screen w-screen flex">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts">
import backgroundPath from '@/../assets/background.jpg';
import { useTheme } from '@/helpers/theme';

const { currentTheme } = useTheme();
</script>
